

.upload{
  margin-left: 40%;
  margin-top: 4%;
}

.input-image{
  max-height: 450px;
  max-width: 600px;
  margin-left: 10%;
  margin-top: 2%;
}

.output-image{
  max-height: 450px;
  max-width: 600px;
  margin-left: 10%;
  margin-top: 2%;
}

button{
  margin-left: 40%;
  margin-top: 2%;
  height: 35px;
  width: 100px;
}

.num-objects{
  margin-left: 35%;
  margin-top: 1%;
}
